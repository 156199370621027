import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';

import { useFlags } from 'launchdarkly-react-client-sdk';

import useTracker from 'src/lib/js/hooks/useTracker';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import UhOh, { getUhOhPropsForError } from 'shared/components/uh_oh/UhOh';

import EmptyCart from 'public/components/default_template/online_ordering/cart/EmptyCart';
import CheckoutErrorModal from 'public/components/default_template/online_ordering/checkout/CheckoutErrorModal';
import { useSpi } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import { useCart } from 'public/components/online_ordering/CartContext';
import { CheckoutFormData, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { usePayment, getCheckoutInfo } from 'public/components/online_ordering/PaymentContext';

import CheckoutSections from './CheckoutSections';
import { getCustomerInfo, useHandleCompletedOrderCallback, getPaymentOption, useHandleValidationError } from './checkoutUtils';
import { useIsCartValid } from './useIsCartValid';

class PaymentNeedsUserInfoError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentNeedsUserInfoError';
  }
}

class PaymentNeededError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentNeededError';
  }
}

export type PaymentCompletedParams = { paymentMethodId?: string|null, surchargeAmount?: number|null };

const CheckoutForm = () => {
  const { ooSpiPreCheckoutValidationEnabled } = useFlags();
  const { spiEnabled } = useSpi();
  const tracker = useTracker();
  const { customer } = useCustomer();
  const { placeOrder, setOrderError, giftCardAppliedAmount, toastCashAppliedAmount, orderTotal } = useCheckout();
  const { loadingCart, cartGuid, cart, error: cartError, refetchCart } = useCart();
  const { completePayment, setUserInfoRequired, createPaymentIntent, confirmedPayment, billingDetails, tipAmount, paymentType, paymentOption } = usePayment();
  const isCartLoading = !cart && loadingCart;
  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: getCustomerInfo(customer)
  });

  const { reset, setValue } = formMethods;
  useEffect(() => {
    if(customer) {
      reset(getCustomerInfo(customer));
    }
  }, [reset, customer]);

  const handleCompletedOrder = useHandleCompletedOrderCallback();

  const onValidationError = useHandleValidationError();
  const { isCartValid } = useIsCartValid(onValidationError, refetchCart);

  const onPaymentCompleted = useCallback(
    (checkoutFormData: CheckoutFormData, resolve: (arg: any) => void) =>
      async (paymentId?: string, firstName?: string, lastName?: string, phone?: string, email?: string, paymentCompletedParams?: PaymentCompletedParams) => {
        // when paying with a digital wallet, user info may not be populated in the form
        await placeOrder(
          cartGuid!,
          {
            ...checkoutFormData,
            yourInfoFirstName: checkoutFormData.yourInfoFirstName || firstName || '',
            yourInfoLastName: checkoutFormData.yourInfoLastName || lastName || '',
            yourInfoPhone: checkoutFormData.yourInfoPhone || phone || '',
            yourInfoEmail: checkoutFormData.yourInfoEmail || email || ''
          },
          handleCompletedOrder,
          paymentId,
          paymentCompletedParams
        );
        resolve(true);
      },
    [placeOrder, cartGuid, handleCompletedOrder]
  );

  const onPaymentCancelled = useCallback(
    (resolve: (val: boolean) => void, reject: (err: Error) => void) => (needsInput: boolean, firstName?: string, lastName?: string, phone?: string, email?: string) => {
      if(needsInput) {
      // populate form fields if some of the user info is available
        if(firstName) setValue('yourInfoFirstName', firstName, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(lastName) setValue('yourInfoLastName', lastName, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(phone) setValue('yourInfoPhone', phone, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(email) setValue('yourInfoEmail', email, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        reject(new PaymentNeedsUserInfoError('We couldn\'t get your information from your digital wallet. Please enter it above.'));
      } else {
        resolve(true);
      }
    }, [setValue]
  );

  const onSubmitHelper = useCallback(async (checkoutFormData: CheckoutFormData) => {
    // payment intent logic not needed (and cannot be used) if there is no amount to charge
    if(orderTotal > 0) {
      return new Promise((resolve, reject) => {
        if(confirmedPayment === null) {
          // we haven't confirmed a payment, so complete the payment
          completePayment(
            checkoutFormData,
            giftCardAppliedAmount,
            toastCashAppliedAmount,
            ooSpiPreCheckoutValidationEnabled ?
              () => isCartValid(
                cartGuid!!,
                {
                  firstName: checkoutFormData.yourInfoFirstName,
                  lastName: checkoutFormData.yourInfoLastName,
                  email: checkoutFormData.yourInfoEmail,
                  phone: checkoutFormData.yourInfoPhone
                }
              )
              : null,
            onPaymentCompleted(checkoutFormData, resolve),
            onPaymentCancelled(resolve, reject),
            err => reject(err)
          );
        } else if(confirmedPayment.amount === orderTotal * 100) {
          // we have already confirmed a payment and the order total hasn't changed, so we can place the order without
          // updating the payment
          const { firstName, lastName, phoneNumber, email } = getCheckoutInfo(checkoutFormData, billingDetails);
          onPaymentCompleted(checkoutFormData, resolve)(confirmedPayment.externalReferenceId, firstName, lastName, phoneNumber, email);
        } else {
          // the order total changed, so the confirmed payment is no longer valid and we need to collect payment again
          createPaymentIntent().then(() => reject(new PaymentNeededError('Your order has changed. Please re-enter your payment information above.')));
        }
      });
    } else {
      if(!(checkoutFormData.yourInfoFirstName || checkoutFormData.yourInfoLastName || checkoutFormData.yourInfoPhone || checkoutFormData.yourInfoEmail)) {
        // the user info fields may have been hidden if a digital wallet is selected and a gift card is used to cover the entire amount
        setUserInfoRequired(true);
        throw new PaymentNeedsUserInfoError('Please enter your name, phone number, and email address.');
      } else {
        return await placeOrder(cartGuid!, checkoutFormData, handleCompletedOrder);
      }
    }
  },
  [
    orderTotal,
    completePayment,
    giftCardAppliedAmount,
    toastCashAppliedAmount,
    onPaymentCompleted,
    onPaymentCancelled,
    setUserInfoRequired,
    placeOrder,
    cartGuid,
    handleCompletedOrder,
    billingDetails,
    confirmedPayment,
    createPaymentIntent,
    ooSpiPreCheckoutValidationEnabled,
    isCartValid
  ]);

  const onSubmit = useCallback(async (checkoutFormData: CheckoutFormData) => {
    tracker.track('Place Order clicked', {
      spiEnabled,
      preCheckoutValidationEnabled: ooSpiPreCheckoutValidationEnabled,
      restaurantGuid: cart?.restaurant?.guid,
      diningOption: cart?.diningOptionBehavior,
      fulfillmentTime: cart?.fulfillmentType,
      numItems: cart?.order?.numberOfSelections,
      subtotal: cart?.order?.preDiscountItemsSubtotal,
      tax: cart?.order?.taxV2,
      deliveryChargeTotal: cart?.order?.deliveryServiceCharges,
      gratuityServiceCharges: cart?.order?.gratuityServiceCharges,
      processingServiceCharges: cart?.order?.processingServiceCharges,
      nonDeliveryNonGratuityNonUbpServiceCharges: cart?.order?.nonDeliveryNonGratuityNonUbpServiceCharges,
      discounts: cart?.order?.discountsTotal,
      total: cart?.order?.totalV2,
      paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal),
      giftCardApplied: giftCardAppliedAmount > 0,
      tipAmount
    });

    if(spiEnabled) {
      try {
        await onSubmitHelper(checkoutFormData);
      } catch(error) {
        if(error instanceof PaymentNeedsUserInfoError || error instanceof PaymentNeededError) {
          setOrderError({
            type: 'CUSTOM_ERROR_MESSAGE',
            message: error.message
          });
        } else if(error) {
          setOrderError(error);
        }
        // if the onSubmitHelper promise is rejected without an error, it is assumed that orderError has already been set
      }
    } else {
      try {
        await placeOrder(cartGuid!, checkoutFormData, handleCompletedOrder);
      } catch(error) {
        setOrderError(error);
      }
    }
  }, [
    tracker,
    cart,
    tipAmount,
    paymentType,
    paymentOption,
    giftCardAppliedAmount,
    orderTotal,
    spiEnabled,
    onSubmitHelper,
    placeOrder,
    cartGuid,
    handleCompletedOrder,
    setOrderError,
    ooSpiPreCheckoutValidationEnabled
  ]);

  if(cartError) {
    return <UhOh {...getUhOhPropsForError(cartError)} />;
  }

  if(isCartLoading) {
    return (
      <div className="checkoutForm emptyCart">
        <section className="checkoutSection currentSection">
          <Skeleton width="100%" height="500px" />
        </section>
      </div>
    );
  }

  if((cart?.order?.selections?.length || 0) === 0) {
    return <EmptyCart />;
  }

  return (
    <div className="checkoutForm">
      <FormProvider {...formMethods}>
        <form className="checkoutFormContents" onSubmit={formMethods.handleSubmit(onSubmit)}>
          {formMethods.formState.isSubmitting && <LoadingSpinnerOverlay withBorderRadius={false} fullScreen={true} />}
          <CheckoutSections />
        </form>
      </FormProvider>
      <CheckoutErrorModal />
    </div>
  );
};

export default CheckoutForm;
